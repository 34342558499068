
























import { Component } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import Editor from '@tinymce/tinymce-vue'
import tokenHelper from '@/shared/helpers/token.helper'
import { getApiUrlByVersion } from '@/config'

document.addEventListener('focusin', (e) => {
  // @ts-ignore
  if (e?.target?.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
    e.stopImmediatePropagation()
  }
})

@Component({
  components: { Editor },
})
export default class EditorField extends AbstractField {
  showEditor: boolean = false

  created() {
    setTimeout(() => {
      this.showEditor = true
    }, 100)
  }

  editorOptions: any = {
    plugins: 'lists table image link preview',
    height: 500,
    file_picker_types: 'image',
    content_style: 'body { font-family: Arial; } p { margin: 0 }',
    block_unsupported_drop: false,
    paste_data_images: true,
    toolbar: 'undo redo | styleselect | fontselect | fontsizeselect | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | bullist numlist | image table link | removeformat',
    images_upload_handler: (blobInfo: any, success: any, failure: any) => {
      let xhr: any
      let formData: FormData

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', `${ getApiUrlByVersion() }/editor-photos`)

      xhr.setRequestHeader('Authorization', `Bearer ${ tokenHelper.getToken() }`)

      xhr.onload = () => {
        let json

        if (xhr.status === 403) {
          failure('HTTP Error: ' + xhr.status, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure('HTTP Error: ' + xhr.status)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location != 'string') {
          failure('Invalid JSON: ' + xhr.responseText)
          return
        }

        success(json.location)
      }

      xhr.onerror = () => {
        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status)
      }

      formData = new FormData()
      formData.append('file', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    },
  }
}
